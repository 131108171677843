import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { TimelineEventProps } from './models';
import './TimelineEvent.scss';

const TimelineEvent: FC<TimelineEventProps> = (props) => {
  const {
    title,
    text,
    textStyle = 'standard',
    image,
    imageAlt = `${title} event image`,
    showImagesOnMobile = true,
    imagePosition = 'right',
    imageStyle = 'horizontal',
    className,
    isLazyLoading,
  } = props;

  const containOnlyImage = useMemo(() => !title && !text && image, [title, text, image]);

  return (
    <div
      className={classnames('timeline-event', className, {
        'timeline-event--only-image': containOnlyImage,
      })}
    >
      {image?.childImageSharp ? (
        <GatsbyImage
          isLazyLoading={isLazyLoading}
          alt={imageAlt}
          fluid={image?.childImageSharp.fluid}
          className={classnames('timeline-event__image', {
            'timeline-event__image--hidden-on-mobile': !showImagesOnMobile,
            [`timeline-event__image--${imageStyle}`]: imageStyle,
            [`timeline-event__image--${imagePosition}`]: imagePosition,
          })}
        />
      ) : null}
      {containOnlyImage ? null : <div className="timeline-event__separator" />}
      {title ? <div className="timeline-event__title">{title}</div> : null}
      {text ? (
        <DangerouslySetInnerHtml
          className={classnames('timeline-event__text', {
            [`timeline-event__text--${textStyle}`]: textStyle,
          })}
          html={text}
        />
      ) : null}
    </div>
  );
};

export default TimelineEvent;
